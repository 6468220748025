<div class="table">
    <mat-card>
        <mat-table [dataSource]="dataSource" *ngIf="displayedColumns && displayedColumns.length > 0">

            <!-- Title Column -->
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="content">
                        <span>
                            {{row?.title}}
                        </span>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Text Column -->
            <ng-container matColumnDef="text">
                <mat-header-cell *matHeaderCellDef>
                    Text
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="content">
                        {{row?.text}}
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row" [cdkDetailRow]="row"
                     [cdkDetailRowTpl]="tpl" (toggleChange)="onToggleChange($event)">
            </mat-row>
        </mat-table>

        <ng-template #tpl let-element>
            <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
                <div class="content">
                    <div class="left">
                        <h3>Title: {{element?.title}}</h3>
                        <p>{{element?.text}}</p>
                        <div class="buttons">
                            <button mat-raised-button
                                    (click)="openEditor(element)"
                                    class="cta icon" color="primary" matTooltipPosition="left"
                                    [matTooltip]="'app.button.editContent' | translate">
                                <mat-icon svgIcon="circle-edit-outline"></mat-icon>
                            </button>
                            <button (click)="deleteTemplate(element)" matTooltipPosition="right"
                            [matTooltip]="'app.button.deleteContent' | translate" class="delete" mat-icon-button>
                                <mat-icon svgIcon="trash-can"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="right">
                        <h3>{{'app.audience.table.column.actions' | translate}}:</h3>
                        <mat-chip-list>
                            <mat-chip *ngFor="let action of element?.interaction">
                                {{action.action}}
                            </mat-chip>
                        </mat-chip-list>

                    </div>
                </div>
            </div>
        </ng-template>

        <div class="table-skeleton" *ngIf="tableIsLoading">
            <div class="row" *ngFor="let item of [].constructor(10)">
                <app-skeleton skelHeight="30"></app-skeleton>
            </div>
        </div>

        <div class="error" *ngIf="!tableIsLoading && dataSource.data.length === 0">
            <mat-hint>No contents found</mat-hint>
        </div>

        <div class="error" *ngIf="loadingFailed">
            <button class="btn-big" mat-raised-button color="primary" (click)="reload.emit(true)">
                <mat-icon svgIcon="reload"></mat-icon>
                {{'app.button.retry' | translate}}
            </button>
        </div>

    </mat-card>

    <div class="paginator" *ngIf="!tableIsLoading && !loadingFailed">
        <button class="prev" (click)="prevPage.emit()" mat-flat-button [disabled]="!hasPrevPage">
            <mat-icon svgIcon="chevron-left"></mat-icon>
        </button>
        <button class="next" (click)="nextPage.emit()" mat-flat-button [disabled]="!hasNextPage">
            <mat-icon svgIcon="chevron-right"></mat-icon>
        </button>
    </div>

</div>
