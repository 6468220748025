import { Observable } from 'rxjs';
import { ConfigService } from './../../../../services/config/config.service';
import { PortalService } from './../../../../services/portal/portal.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from 'src/app/store/app.reducer';
import * as configActions from 'src/app/store/config/config.actions';
import { Config } from 'src/app/interfaces/config';

@Component({
  selector: 'app-setup-steps',
  templateUrl: './setup-steps.component.html',
  styleUrls: ['./setup-steps.component.scss'],
})
export class SetupStepsComponent implements OnInit {
  setupStep = 0;
  customer = '';
  licenseForm: FormGroup;
  apiKeyForm: FormGroup;
  profileForm: FormGroup;
  licenseDate = '';
  licensedFor = '';
  licenseDomain = '';
  enabledFeatures = 0;
  hide = true;

  isLoading = false;
  isLicenseFalse = false;

  configStore: Observable<Config>;

  @HostListener('document:keydown.enter') onKeydownHandler() {
    if (this.setupStep === 1) {
      this.next();
    }
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private portalService: PortalService,
    private configService: ConfigService,
    private store: Store<fromApp.AppState>
  ) {
    this.configStore = this.store.select('config');
  }

  ngOnInit(): void {
    this.licenseForm = this.fb.group({
      license: ['', [Validators.required]],
    });
    this.apiKeyForm = this.fb.group({
      apiKey: ['', [Validators.required]],
      apiSecret: ['', [Validators.required]],
    });
    this.profileForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: ['', [Validators.required, Validators.pattern(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_.])[A-Za-z\d@$!%*?&-_.]{8,}$/
      )]],
    });
  }
  next() {
    switch (this.setupStep) {
      case 0:
        if (this.licenseCheck.valid) {
          this.isLicenseFalse = false;

            this.isLoading = true;
            this.portalService.activateLicense(this.licenseForm.value.license).subscribe(
              res => {
                this.configService.findOne('').subscribe(config => {
                  this.store.dispatch(new configActions.UpdateConfig(config.detail));
                  this.isLoading = false;
                  this.setupStep++;
                })
              },
              err => {
                this.isLoading = false;
                this.isLicenseFalse = true;
              }
              )

        }
        break;

      case 1:
        this.setupStep++;
        break;

      case 2:
        this.setupStep++;
        break;

      case 3:
        this.router.navigate(['/login']);
        break;
    }
  }
  back() {
    this.setupStep--;
  }

  save(event) {
    // console.log('You entered: ', event.target.value);
  }

  get email() {
    return this.profileForm.get('email');
  }
  get password() {
    return this.profileForm.get('password');
  }
  get licenseCheck() {
    return this.licenseForm.get('license');
  }
  get apiKeyCheck() {
    return this.apiKeyForm.get('apiKey');
  }
  get apiSecretCheck() {
    return this.apiKeyForm.get('apiSecret');
  }
}
