import { User } from './../interfaces/user';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;

  constructor() { }

  saveUserInformation(user: User){
    this.user = user;
  }

  getUserInformation(){
    const storedUser = JSON.parse(window.localStorage.getItem('token')).detail.user;
    if (this.user === undefined) {
      this.user = storedUser;
    }
    return this.user;
  }

}
