<div class="search-header">
    <img class="cloud" [src]="'assets/images/setup_image_cloud_left.svg'" alt="">
    <img class="paperplane" [src]="'assets/images/paperplane.svg'" alt="">
    <div class="container">
        <div class="search-box">
            <mat-form-field floatLabel='never'>
                <mat-placeholder>Search by title</mat-placeholder>
                <input matInput type="text" (keydown)="runSearch()"
                       [(ngModel)]="searchParam.search">
                <button mat-button *ngIf="searchParam?.search" matSuffix mat-icon-button aria-label="Clear"
                        (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="openEditor()">
                New content
            </button>
        </div>
    </div>
</div>
<main class="content">
    <div class="container">
        <app-content-table-card
                [hasPrevPage]="hasPrevPage"
                [hasNextPage]="hasNextPage"
                [dataSource]="dataSource"
                [currentPage]="currentPage"
                [overallSize]="overallSize"
                [tableIsLoading]="isLoading"
                [loadingFailed]="isLoadingFailed"
                [displayedColumns]="displayedColumns"
                (prevPage)="prevPage()"
                (nextPage)="nextPage()"
                (reload)="reloadPage($event)">
        </app-content-table-card>
    </div>
</main>
<app-footer></app-footer>
