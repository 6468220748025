<div [ngSwitch]="data.type" class="snackbar-content">
    <div *ngSwitchCase="0">
        <mat-icon svgIcon="check-circle-outline"></mat-icon>
        {{ 'app.toast.passwordReset' | translate }}
    </div>

    <div *ngSwitchCase="1">
        <mat-icon svgIcon="alert-circle-outline"></mat-icon>
            {{ 'app.toast.errorData' | translate }}
    </div>
</div>
