import { ContentEditorComponent } from './../../components/content-editor/content-editor.component';
import { MatDialog } from '@angular/material/dialog';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentService} from '../../services/content/content.service';
import {Subject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {Content} from '../../interfaces/content';
import {debounceTime} from 'rxjs/operators';
import {SearchParam} from '../../interfaces/base/search-param';
import {ListResponse} from '../../interfaces/base/response';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {

    public readonly defaultLimit = 10;

    public readonly defaultOffset = 0;

    public readonly defaultMinPage = 0;

    searchParam: SearchParam = {
        limit: this.defaultLimit,
        offset: this.defaultOffset,
        search: ''
    };
    private searchInput$: Subject<string> = new Subject<string>();
    private searchSubscription: Subscription;

    overallSize = 0;
    isLoading = false;
    hasPrevPage = false;
    hasNextPage = false;
    isLoadingFailed = false;
    currentPage = this.defaultMinPage;
    displayedColumns = ['title', 'text'];
    dataSource: MatTableDataSource<Content> = new MatTableDataSource<Content>();

    private subscribedSubscriptions: Subscription = new Subscription();

    constructor(
        private contentService: ContentService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.initSearchSubscription();
        this.findAllContents(this.searchParam);
    }

    ngOnDestroy(): void {
        // unsubscribe all subscriptions
        this.subscribedSubscriptions.unsubscribe();
    }

    initSearchSubscription() {
        this.subscribedSubscriptions.add(this.searchSubscription = this.searchInput$
            .pipe(debounceTime(1000))
            .subscribe(() => {
                this.findAllContents(this.searchParam);
            }));
        this.subscribedSubscriptions.add(this.searchInput$);
    }

    findAllContents(searchParams?: SearchParam) {
        this.isLoading = true;
        this.subscribedSubscriptions.add(this.contentService.findAll(searchParams).subscribe((response: ListResponse<Content>) => {
            this.isLoading = false;
            this.isLoadingFailed = false;
            this.overallSize = response.overallsize;
            // overall size is counting from 1 but offset is counting from 0.
            // if overall is bigger then offset and returned list is full - means that there are more records.
            this.hasNextPage = ((response.overallsize - 1) - this.searchParam.offset) > 0 && response.list.length >= this.defaultLimit;
            this.hasPrevPage = this.currentPage > this.defaultMinPage;
            this.dataSource.data = response.list;
        }, () => {
            this.isLoading = false;
            this.isLoadingFailed = true;
        }));
    }

    runSearch() {
        this.searchInput$.next();
    }

    clearSearch() {
        this.searchParam.search = '';
        this.searchInput$.next();
    }

    reloadPage(emitEvent) {
        if (emitEvent) {
            this.findAllContents(this.searchParam);
        }
    }

    prevPage() {
        if (this.currentPage > this.defaultMinPage) {
            this.currentPage--;
            this.searchParam.offset -= this.defaultLimit;
            this.findAllContents(this.searchParam);
        }
    }

    nextPage() {
        this.currentPage++;
        this.searchParam.offset += this.defaultLimit;
        this.findAllContents(this.searchParam);
    }

    openEditor(){
        this.dialog.open(ContentEditorComponent, {})
        .afterClosed()
        .subscribe((res) => {
          if (res === true) {
            setTimeout(() => {
              this.findAllContents(this.searchParam);
            }, 2000);
          }
        });
    }

}
