<div [ngSwitch]="data.valid">
    <h2 *ngSwitchCase="false" mat-dialog-title>{{'app.wizard.panel.audience.importDialog.failed.headline' | translate}}
    </h2>
     <h2 *ngSwitchDefault mat-dialog-title>{{'app.wizard.panel.audience.importDialog.headline' | translate}}
     </h2>
</div>
<mat-dialog-content>
    <div class="content">
    <div class="file-icon">
        <div class="circle-background" [ngClass]="{red: !data.valid}">
            <mat-icon *ngIf="data.valid; else invalid" svgIcon="file-delimited-outline" class="icon-white"></mat-icon>
            <ng-template #invalid>
                <mat-icon svgIcon="alert" class="icon-white">
                </mat-icon>
            </ng-template>
        </div>
    </div>

    <div>
        <div [ngSwitch]="data.valid">
            <div *ngSwitchCase="false">
                <p
                    [innerHTML]="'app.wizard.panel.audience.importDialog.failed.content' | translate :{filename: data.fileName}">
                </p>
            </div>
            <div *ngSwitchDefault>
                <p>
                    <strong>
                        {{'app.wizard.panel.audience.importDialog.uploadInfo' | translate}}
                    </strong>
                    <br>
                    {{ data.fileName }}

                </p>

                <div *ngIf="data.valid">
                    <p>
                        <strong>
                            {{'app.wizard.panel.audience.importDialog.keywords' | translate}}
                        </strong>
                        <br>
                        {{data.csvData.length}}
                    </p>
                </div>
            </div>
        </div>
    
</div>
</div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog(false)">{{'app.button.cancel' | translate}}</button>
    <button [disabled]="!data.valid" color="primary" mat-flat-button cdkFocusInitial
            (click)="data.valid ? closeDialog(true): ''">{{'app.button.importFile' | translate}}</button>
</mat-dialog-actions>
