import { DynamicEnvironment } from './dynamic-enviroments';
class Enviroment extends DynamicEnvironment {
  public production: boolean;
  public appVersion: string;
  constructor() {
    super();

    this.appVersion = require('../../package.json').version + '-dev';
    this.production = false;
  }
}

export const environment = new Enviroment();
