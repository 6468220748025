import { DetailResponse } from './../../interfaces/base/response';
import { Injectable } from '@angular/core';
import { CrudService } from '../generic/crud/crud.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VersionService extends CrudService {
  // get api url from environment file (The field is extension of the super class field)
  protected url = environment.api + '/system?secure=backendMogree123!';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getVersion(){
    return this.httpClient.get<DetailResponse<any>>(`${this.url}`, this.jsonHeaders);
  }

}
