import { Config } from './../interfaces/config';
import { ActionReducerMap } from '@ngrx/store';

import * as config from './config/config.reducer';
import * as version from './version/version.reducer';

export interface AppState {
  config: Config;
  version: number;
}

export const appReducer: ActionReducerMap<AppState> = {
  config: config.configStore,
  version: version.versionStore
};
