<div class="search-header">
    <img class="cloud" src="../../../assets/images/setup_image_cloud_left.svg" alt="">
    <img class="paperplane" src="../../../assets/images/paperplane.svg" alt="">
    <div class="container">
        <div class="search-box">
            <mat-form-field floatLabel='never'>
                <mat-placeholder>{{'app.dashboard.history.searchPlaceholder' | translate}}</mat-placeholder>
                <input matInput type="text" (keydown)="runSearch()" [(ngModel)]="searchParam.search">
                <button mat-button *ngIf="searchParam.search" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <button color="primary" mat-raised-button (click)="openWizard(0,0,2)" >New automation</button>
        </div>
    </div>
</div>
<main class="content">
    <div class="container">
        <mat-tab-group>
            <mat-tab [label]="'app.automate.tabs.active' | translate">
                <app-table-card 
                campaignStatus="publish" 
                [update]="update" 
                [searchTerm]="searchTerm"
                [hasPrevPage]="hasPrevPage"
                [hasNextPage]="hasNextPage"
                [dataSource]="dataSource"
                [currentPage]="currentPage"
                [overallSize]="overallSize"
                [tableIsLoading]="isLoading"
                [loadingFailed]="isLoadingFailed"
                [displayedColumns]="displayedColumns"
                (prevPage)="prevPage(0)"
                (nextPage)="nextPage(0)"
                (reload)="reloadPage($event, 0)"
                (campaignToggle)="updateCampaign($event)"
                >
            </app-table-card>
            </mat-tab>
            <mat-tab [label]="'app.automate.tabs.paused' | translate">
                <app-table-card 
                campaignStatus="draft" 
                [update]="update" 
                [searchTerm]="searchTerm"
                [hasPrevPage]="pausedHasPrevPage"
                [hasNextPage]="pausedHasNextPage"
                [dataSource]="pausedDataSource"
                [currentPage]="pausedCurrentPage"
                [overallSize]="pausedOverallSize"
                [tableIsLoading]="isLoading"
                [loadingFailed]="isLoadingFailed"
                [displayedColumns]="displayedColumns"
                (prevPage)="prevPage(1)"
                (nextPage)="nextPage(1)"
                (reload)="reloadPage($event, 1)"
                (campaignToggle)="updateCampaign($event)"
                >
            </app-table-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</main>
<app-footer></app-footer>