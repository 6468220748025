<nav>
    <div class="container">
        <div class="main-section">
            <a [routerLink]="['/dashboard']" class="logo">
                <img src="../../../assets/images/logo_icon.svg" alt="Customer Portal by mogree">
                <span>
                    {{ (config | async)?.customer.name }}
                </span>
            </a>
            <ul>
                <!-- <li *ngFor="let menu of navigationMenu" >
                    <a routerLinkActive="router-link-active" [routerLink]="[menu.keyword]">{{menu.keyword}}</a>
                </li> -->
                <li>
                    <a [routerLink]="['dashboard']"
                        routerLinkActive="router-link-active">{{'app.navigation.dashboard' | translate}}</a>
                </li>
                <li  *ngIf="enableContent"><a [routerLink]="['content']" routerLinkActive="router-link-active">{{'app.navigation.content' | translate}}</a></li>
                <li *ngIf="enableAudience">
                    <a [routerLink]="['audience']"
                        routerLinkActive="router-link-active">{{'app.navigation.audience' | translate}}</a>
                </li>
                <li>
                    <a [routerLink]="['automate']"
                        routerLinkActive="router-link-active">{{'app.navigation.automate' | translate}}</a>
                </li>
            </ul>
        </div>
        <div class="section">
            <!--            <a [routerLink]="['/analytics']" routerLinkActive="router-link-active">-->
            <!--                <mat-icon svgIcon="google-analytics"></mat-icon>-->
            <!--            </a>-->
            <div class="profile-wrapper">
                <div class="profile pointer" [matMenuTriggerFor]="menu" (click)="toggleProfile()">
                    <mat-icon svgIcon="account" color="accent"></mat-icon>
                    <span>
                        {{ username }}
                    </span>
                </div>
                <mat-menu #menu="matMenu">
                    <a mat-menu-item [routerLink]="['account']">
                        <mat-icon>settings</mat-icon>
                        {{'app.navigation.menu.settings' | translate}}
                    </a>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        {{'app.navigation.menu.logout' | translate}}
                    </button>
                </mat-menu>
            </div>
        </div>

    </div>
</nav>