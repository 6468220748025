import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  @Input() skelWidth: string;
  @Input() skelHeight: string;
  @Input() skelCircle: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  getStyles() {
    const myStyles = {
      'width.px': this.skelWidth ? this.skelWidth : '',
      'height.px': this.skelHeight ? this.skelHeight : '',
      'border-radius': this.skelCircle ? '50%' : ''
    };
    return myStyles;
  }

}
