<div class="background dashboard">
        <div class="container">
            <header>
                <h1>Customer Portal</h1>
                <h2>{{'app.copyright' | translate}} mogree.</h2>
                <div class="quick-actions">
                    <button matTooltipHideDelay="below" [matTooltip]="'app.button.cta.tooltip.sendNow' | translate"  mat-raised-button color="primary" (click)="openWizard(0,0,0)" >{{'app.button.cta.SendNow' | translate}}</button>
                    <button matTooltipHideDelay="below" [matTooltip]="'app.button.cta.tooltip.schedule' | translate"  mat-raised-button color="primary" (click)="openWizard(0,0,1)" >{{'app.button.cta.Schedule' | translate}}</button>
                </div>
                <img class="cloud" src="../../../assets/images/setup_image_cloud_left.svg" alt="">
                <img class="lines" src="../../../assets/images/lines.svg" alt="">
                <img class="paperplane" src="../../../assets/images/paperplane.svg" alt="">
            </header>
        </div>
            <div class="cards" [ngSwitch]="isLoading">
                <div class="container">
                <mat-card>
                    <mat-card-title>
                        <mat-icon color="accent">
                            supervisor_account
                        </mat-icon>
                    </mat-card-title>
                    <mat-card-content>
                        <app-skeleton *ngSwitchCase="true" skelWidth="150" skelHeight="55" ></app-skeleton>
                        <h3 *ngSwitchCase="false">{{dashboardData.audience_reached_since}}</h3>
                        <mat-hint>
                            {{ 'app.dashboard.stats.usersIn24Hours' | translate }}
                        </mat-hint>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-title>
                        <mat-icon color="accent">
                            done
                        </mat-icon>
                    </mat-card-title>
                    <mat-card-content>
                        <app-skeleton *ngSwitchCase="true" skelWidth="150" skelHeight="55"></app-skeleton>
                        <h3 *ngSwitchCase="false">{{dashboardData.audience_reached_overall}}</h3>
                        <mat-hint>
                            {{'app.dashboard.stats.usersReached' | translate}}
                        </mat-hint>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-title>
                        <mat-icon class="icon-info" svgIcon="cellphone-message">
                        </mat-icon>
                    </mat-card-title>
                    <mat-card-content>
                        <app-skeleton *ngSwitchCase="true" skelWidth="150" skelHeight="55" ></app-skeleton>
                        <h3 *ngSwitchCase="false">{{dashboardData.active_registrations}}</h3>
                        <mat-hint>
                            {{ 'app.dashboard.stats.activeRegistered' | translate }}
                        </mat-hint>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-title>
                        <mat-icon color="accent">
                            mail
                        </mat-icon>
                    </mat-card-title>
                    <mat-card-content>
                        <app-skeleton *ngSwitchCase="true" skelWidth="150" skelHeight="55"></app-skeleton>
                        <h3 *ngSwitchCase="false">{{dashboardData.audience_opened_overall_percent}}</h3>
                        <mat-hint>
                           {{'app.dashboard.stats.percentageOfOpened' | translate}}
                        </mat-hint>
                    </mat-card-content>
                </mat-card>
<!--                <mat-card>-->
<!--                    <mat-card-title>-->
<!--                        <mat-icon color="accent">-->
<!--                            campaign-->
<!--                        </mat-icon>-->
<!--                    </mat-card-title>-->
<!--                    <mat-card-content>-->
<!--                        <app-skeleton *ngSwitchCase="true" skelWidth="150" skelHeight="55"></app-skeleton>-->
<!--                        <h3 *ngSwitchCase="false">{{dashboardData.created_campaigns}}</h3>-->
<!--                        <mat-hint>-->
<!--                           {{'app.dashboard.stats.createdCampaigns' | translate}}-->
<!--                        </mat-hint>-->
<!--                    </mat-card-content>-->
<!--                </mat-card>-->
                </div>
            </div>
            <div class="history">
                <div class="container">
                    <h4>{{'app.dashboard.history.headline' | translate}}</h4>
                    <div class="search-filter">
                        <mat-card class="search-card">
                                <mat-form-field class="search full-width" floatLabel="never">
                                    <mat-placeholder class="placeholder">{{'app.dashboard.history.searchPlaceholder' | translate}}
                                    </mat-placeholder>
                                    <input matInput (keydown)="runSearch()" [(ngModel)]="searchParam.search" type="text">
                                    <button mat-button *ngIf="searchParam.search" matSuffix mat-icon-button aria-label="Clear"
                                    (click)="clearSearch()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
      
                            <mat-form-field floatLabel="never">
                                <mat-label>{{'app.dashboard.history.filterPlaceholder' | translate}}</mat-label>
                                <mat-select [(ngModel)]="searchParam.create_type"
                                    (selectionChange)="filter()">
                                    <mat-option value="web" >{{ 'app.dashboard.table.data.creatorType1' | translate}}</mat-option>
                                    <mat-option value="remoteapi" >{{ 'app.dashboard.table.data.creatorType3' | translate}}</mat-option>
                                </mat-select>
                                  <button mat-button *ngIf="searchParam.create_type" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearFilter(); $event.stopPropagation()">
                                      <mat-icon>close</mat-icon>
                                  </button>
                            </mat-form-field>
                        </mat-card>
                    </div>
                <app-table-card 
                    campaignStatus="completed" 
                    [update]="update" 
                    [searchTerm]="searchTerm"
                    [hasPrevPage]="hasPrevPage"
                    [hasNextPage]="hasNextPage"
                    [dataSource]="dataSource"
                    [currentPage]="currentPage"
                    [overallSize]="overallSize"
                    [tableIsLoading]="tableIsLoading"
                    [loadingFailed]="isLoadingFailed"
                    [displayedColumns]="displayedColumns"
                    (prevPage)="prevPage()"
                    (nextPage)="nextPage()"
                    (reload)="reloadPage($event)">
                </app-table-card>
            </div>
        </div>
        <app-footer></app-footer>
