import { Action } from '@ngrx/store';

export const UPDATE_VERSION = 'UPDATE_VERSION';
export const DELETE_VERSION = 'DELETE_VERSION';

export class UpdateVersion implements Action {
  readonly type = UPDATE_VERSION;
  constructor(public payload) {}
}

export class DeleteVersion implements Action {
  readonly type = DELETE_VERSION;
}

export type VersionActions = UpdateVersion | DeleteVersion;
