<mat-card class="license">
    <mat-card-title [ngSwitch]="setupStep">
            <span *ngSwitchCase="3">{{'app.setup.headline.adminSetup' | translate}} </span>
            <span *ngSwitchCase="2">{{'app.setup.headline.portalSetup' | translate}} </span>
            <span *ngSwitchCase="1">{{'app.setup.headline.welcomeSetup' | translate}} <span>{{customer}}</span></span>
            <span *ngSwitchDefault>{{'app.setup.headline.verifyLicense' | translate}}</span>
    </mat-card-title>
    <mat-card-content>

        <div [ngSwitch]="setupStep">
            
            <div *ngSwitchCase="1" class="full-width t-left">
                    <p *ngIf="(configStore | async)?.licences.length !== 0" >{{ 'app.setup.license.licenseValidUntil' | translate }}: <span>{{ (configStore | async)?.licences[0].expire_date | localDate: "dd.MM.yyyy" }}</span></p>
                    <!-- <p>{{ 'app.setup.license.licenseValidFor' | translate }}: <span>{{ licensedFor }}</span></p> -->
                    <!-- <p>{{ 'app.setup.license.licenseDomain' | translate }}: <span>{{ licenseDomain }}</span></p> -->
                    <br>
                    <p>{{ (configStore | async)?.features.length }} {{ 'app.setup.enabledFeatures' | translate }}</p>
                    <br>
                    <button routerLink="/dashboard" mat-raised-button color="primary">
                    <!-- {{'app.button.confirm' | translate}} -->
                    {{'app.button.toDashboard' | translate }}
                </button>
            </div>
            <div *ngSwitchCase="2" class="full-width">
                <form (submit)="next()" [formGroup]="apiKeyForm">
                    <mat-form-field class="full-width">
                        <mat-label>{{'app.setup.api.apiKeyLabel' | translate}}</mat-label>
                        <input type="text" formControlName="apiKey" matInput />
                        <mat-error *ngIf="apiKeyCheck.invalid && apiKeyCheck.touched">
                            {{ 'app.setup.api.error.apiKey' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width">
                        <mat-label>{{'app.setup.api.secretKeyLabel' | translate}}</mat-label>
                        <input type="text" formControlName="apiSecret" matInput />
                        <mat-error *ngIf="apiSecretCheck.invalid && apiSecretCheck.touched">
                            {{ 'app.setup.api.error.apiSecret' | translate }}</mat-error>
                    </mat-form-field>

                    <button [disabled]="apiKeyCheck.invalid || apiSecretCheck.invalid" mat-raised-button color="primary"
                        type="submit">
                        {{'app.button.next' | translate}}
                    </button>
                </form>
            </div>
            <div *ngSwitchCase="3" class="full-width">
                <p>{{'app.setup.account.headline' | translate}}</p>
                <form (submit)="next()" [formGroup]="profileForm">
                    <mat-form-field class="full-width">
                        <mat-label>{{'app.setup.account.mailLabel'}}</mat-label>
                        <input required type="text" formControlName="email" matInput appMatInputAutofocus />
                        <mat-error *ngIf="email.invalid && apiKeyCheck.touched">
                            {{ 'app.login.error.validMail' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width password">
                        <mat-label>{{'app.setup.account.passwordLabel' | translate}}</mat-label>
                        <input required [type]="hide ? 'password' : 'text'" formControlName="password" matInput />
                        <mat-icon class="pointer" *ngIf="password.value" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="password.invalid && apiSecretCheck.touched">
                            {{ 'app.login.error.password' | translate }}</mat-error>
                    </mat-form-field>

                     <button [disabled]="email.invalid || password.invalid" mat-raised-button color="primary"
                         type="submit">
                         {{'app.button.completeSetup' | translate}}
                     </button>
                </form>
            </div>

            <div *ngSwitchCase="0">
                <form [formGroup]="licenseForm" (submit)="next()">
                    <mat-form-field class="full-width">
                        <mat-label>{{'app.setup.license.licenseLabel' | translate}}</mat-label>
                        <textarea (keyup.enter)="next()" rows="10" [placeholder]="'app.setup.license.example' | translate"
                            formControlName="license" matInput appMatInputAutofocus></textarea>
                        <mat-error *ngIf="licenseCheck.invalid && licenseCheck.touched">
                            {{ 'app.setup.license.error' | translate }}</mat-error>
                        </mat-form-field>
                        <mat-error class="invalid-license" *ngIf="isLicenseFalse">
                            {{ 'app.setup.license.invalid' | translate }}
                        </mat-error>
                    <button [disabled]="licenseCheck.invalid" mat-raised-button color="primary">
                        {{'app.button.verify' | translate}}
                    </button>
                </form>
            </div>

        </div>

        <!-- <button *ngIf="setupStep > 0" class="back" (click)="back()" mat-flat-button>
            <mat-icon>arrow_back</mat-icon>
        </button> -->

        <div class="info">
            <span>
                {{ 'app.login.userAgreement' | translate }}
                <a href="/">{{'app.login.agreement' | translate}}</a>
            </span>
            <a class="gray-link" href="/" translate>{{'app.button.help' | translate}}</a>
        </div>

        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </mat-card-content>
</mat-card>
