import { DetailResponse } from './../../interfaces/base/response';
import { Injectable } from '@angular/core';
import { CrudService } from '../generic/crud/crud.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PortalService extends CrudService {
  // get api url from environment file (The field is extension of the super class field)
  protected url = environment.api + '/portal';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  activateLicense(key){
      const body = {
        "itemid": key,
        "type": 0
      }
    return this.httpClient.post<DetailResponse<any>>(`${this.url}/activate`, body, this.jsonHeaders);
  }
}
