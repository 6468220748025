import { UserService } from './../../../../services/user.service';
import { ApiService } from './../../../../services/api.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  step = 0;
  cardType = 0;
  hide = true;
  isLoading = false;
  loginFailed = false;

  loginForm: FormGroup;

  @HostListener('document:keydown.enter') onKeydownHandler() {
    switch (this.step) {
      case 0:
        if (this.cardType === 0 && this.email.valid) {
          this.next();
        }
        if (this.cardType === 1 && this.email.valid) {
          this.resetPassword();
        }
        break;
      case 1:
        if (this.cardType === 0 && this.password.valid) {
          this.next();
        }
        if (this.cardType === 1 && this.email.valid) {
          this.resetPassword();
        }
        break;
    }
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private apiSerive: ApiService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          // Validators.pattern(
          //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_.])[A-Za-z\d@$!%*?&-_.]{8,}$/
          // ),
        ],
      ],
    });
  }

  next() {
    if (this.step === 1) {
      this.isLoading = true;
      this.loginFailed = false;
      this.apiSerive.getAccountLogin(this.loginForm.value).subscribe(
        res => {
          window.localStorage.setItem('token', JSON.stringify(res));
          this.userService.saveUserInformation(res.detail.user);
          this.router.navigateByUrl('/dashboard');
          this.isLoading = false;
          this.loginFailed = false;
        },
        err => {
          this.isLoading = false;
          this.loginFailed = true;
          this.step--;
        }
        );
    }
    this.step++;
  }

  back() {
    this.step--;
  }

  resetAccount() {
    if (this.cardType === 1) {
      this.cardType = 0;
    } else {
      this.cardType = 1;
    }
  }

  resetPassword() {
    this.snackbar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      panelClass: ['accent-snackbar'],
      horizontalPosition: 'right',
      data: {
        type: 0
      }
    });
    this.cardType = 0;
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
