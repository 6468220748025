import { Action } from '@ngrx/store';

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const DELETE_CONFIG = 'DELETE_CONFIG';

export class UpdateConfig implements Action {
  readonly type = UPDATE_CONFIG;
  constructor(public payload) {}
}

export class DeleteConfig implements Action {
  readonly type = DELETE_CONFIG;
}

export type ConfigActions = UpdateConfig | DeleteConfig;
