import { Keywords } from './../interfaces/keywords';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  env = environment;

  token: string;


  constructor(private http: HttpClient) {}

  getAccountLogin(loginData){
    const headers = new HttpHeaders({
      accept: 'application/json',
      'mogree-Mail': loginData.email,
      'mogree-Password': loginData.password
    });

    return this.http.get<any>(this.env.api + '/account/login', {headers});
  }

  getToken(token?: string){
    if (token === undefined) {
      const storedToken = JSON.parse(window.localStorage.getItem('token')).detail.auth_token;
      this.token = storedToken;
    } else {
      this.token = token;
    }
  }

  getKeywords(keyword?: string): Observable<Keywords[]>{
    this.getToken();
    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    let keywords = '/keywords';
    const search = `?search=${keyword}`;
    if (keyword !== undefined) {
      keywords = keywords + search;
    }

    return this.http.get<Keywords[]>(this.env.api + keywords, {headers});
  }

  getAudienceCount(filters: string[], groupId: string){
    this.getToken();
    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
     });

    if (filters.length === 0){
      filters = [];
    }

    const body = {
      audience_group_id: groupId,
      filter_keywords: filters,
      type: 0
    };

    return this.http.post<any>(this.env.api + '/audience_count', body, {headers});
  }

  getCampaign(filter: string, limit: number, offset: number, searchterm?){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    if (searchterm) {
      return this.http.get<any>(this.env.api + '/campaign?filter_status=' + filter + '&limit=' + limit + '&offset=' + offset + '&search=' + searchterm, {headers});

    } else {
      return this.http.get<any>(this.env.api + '/campaign?filter_status=' + filter + '&limit=' + limit + '&offset=' + offset, {headers});
    }

  }

  postCampaign(body){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    return this.http.post<any>(
      this.env.api + '/campaign', body, { headers }
    );
  }

  getConfig(){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    return this.http.get<any>(this.env.api + '/config', {headers});
  }

  getDashboard(){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    return this.http.get<any>(this.env.api + '/dashboard/status', { headers });
  }

  postCSV(file, name){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('groupname', name);

    return this.http.post<any>(
      this.env.api + '/audience/groups/upload', formData, { headers }
    );
  }

  getAudienceGroups(){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    return this.http.get<any>(this.env.api + '/audience/groups?limit=0&offset=0', { headers });
  }

  deleteCampaign(id){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    return this.http.delete<any>(this.env.api + '/campaign/' + id, {
      headers,
    });
  }

  getCampaignStats(itemid: string){
    this.getToken();

    const headers = new HttpHeaders({
      accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
    });

    return this.http.get<any>(this.env.api + '/statistics/campaign/' + itemid , {
      headers,
    });
  }

}
