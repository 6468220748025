import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {AutomateComponent} from './pages/automate/automate.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {LoginComponent} from './pages/login/login.component';
import {SetupComponent} from './pages/setup/setup.component';
import {AudienceComponent} from './pages/audience/audience.component';
import {ContentComponent} from './pages/content/content.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { animationState: 'One' } },
  { path: 'setup', component: SetupComponent, data: { animationState: 'One' } },
  {
    path: 'dashboard',
    component: HomeComponent,
    data: { animationState: 'One' },
  },
  {
    path: 'content',
    component: ContentComponent,
    data: { animationState: 'One' },
  },
  {
    path: 'audience',
    component: AudienceComponent,
    data: { animationState: 'One' },
  },
  {
    path: 'analytics',
    component: PageNotFoundComponent,
    data: { animationState: 'One' },
  },
  {
    path: 'automate',
    component: AutomateComponent,
    data: { animationState: 'Two' },
  },
  {
    path: 'account',
    component: SettingsComponent,
    data: { animationState: 'Three' },
  },
  { path: '**', component: HomeComponent, data: { animationState: 'One' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
