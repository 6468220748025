import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DetailResponse} from '../../interfaces/base/response';
import { Store } from '@ngrx/store';

import * as fromApp from 'src/app/store/app.reducer';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  environment = environment;
  backendInfo: Observable<any>;
  constructor(private store: Store<fromApp.AppState>) {
    this.backendInfo = this.store.select('version');
  }

  ngOnInit(): void {}
}
