import * as VersionActions from './version.actions';

let initialState = 0.0;

export function versionStore(state = initialState, action: VersionActions.VersionActions) {
  switch (action.type) {
    case VersionActions.UPDATE_VERSION:
      return { ...action.payload };

    case VersionActions.DELETE_VERSION:
      return initialState;

    default:
      return state;
  }
}
