import { ContentService } from './../../../services/content/content.service';
import { DeleteConfirmationComponent } from './../../dialogs/delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { ContentEditorComponent } from './../../content-editor/content-editor.component';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {Content} from '../../../interfaces/content';
import {CdkDetailRowDirective} from '../../../directives/cdk-detail-row.directive';

@Component({
    selector: 'app-content-table-card',
    templateUrl: './content-table-card.component.html',
    styleUrls: ['./content-table-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'void',
                style({height: '0px', minHeight: '0', visibility: 'hidden'})
            ),
            state('*', style({height: '*', visibility: 'visible'})),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class ContentTableCardComponent implements OnInit {
    @Input() currentPage = 0;
    @Input() overallSize = 0;
    @Input() hasPrevPage = false;
    @Input() hasNextPage = false;
    @Input() loadingFailed = false;
    @Input() tableIsLoading = false;
    @Input() displayedColumns: string[] = [];
    @Input() dataSource: MatTableDataSource<Content>;

    @Output() prevPage: EventEmitter<void> = new EventEmitter();
    @Output() nextPage: EventEmitter<void> = new EventEmitter();
    @Output() reload: EventEmitter<boolean> = new EventEmitter();

    private openedRow: CdkDetailRowDirective;

    constructor(
        public dialog: MatDialog,
        private contentService: ContentService
    ) {
    }

    ngOnInit(): void {
    }

    onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
        if (this.openedRow && this.openedRow.expended) {
            this.openedRow.toggle();
        }
        this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
    }

    openEditor(content){
        this.dialog.open(ContentEditorComponent, {
            data: content
        }).afterClosed()
        .subscribe((res) => {
          if (res === true) {
            setTimeout(() => {
                this.reload.emit(true);
            }, 2000);
          }
        });
    }

    deleteTemplate(content){
        this.dialog.open(DeleteConfirmationComponent, {
            data: { 
                title: 'Delete content template',
                description: 'Are you sure that you want to delete this template?'
            }
        }).afterClosed()
        .subscribe((res) => {
          if (res === true) {
              this.contentService.delete(content).subscribe(()=> {
                  setTimeout(() => {
                      this.reload.emit(true);
                  }, 2000);
                }
            );
            }
        });
    }

}
