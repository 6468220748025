import { SearchParam } from './../../interfaces/base/search-param';
import { HelperService } from './../helper.service';
import { ListResponse } from './../../interfaces/base/response';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CrudService} from '../generic/crud/crud.service';

@Injectable({
    providedIn: 'root'
})
export class ContentService extends CrudService {

    // get api url from environment file (The field is extension of the super class field)
    protected url = environment.api + '/content';

    constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    getPlaceholder(searchParams?: SearchParam): Observable<ListResponse<any>> {
            const httpParams = HelperService.getHttpParams(searchParams);
            return this.httpClient.get<ListResponse<any>>(`${this.url}/placeholder`, {params: httpParams, headers: this.jsonHeaders.headers});
    }
}
