<div mat-dialog-content class="wizard-content">
    <div class="preview">
        <!-- <mat-expansion-panel style="position: absolute; top: 0; left: 0; width: 100%;">
            <mat-expansion-panel-header>
                Valid to send?
            </mat-expansion-panel-header>
            <div [ngSwitch]="checkContentForm.valid">
                <h3 style="display:flex; align-items: center">
                    <mat-icon class="icon-success" svgIcon="check" *ngSwitchCase="true"></mat-icon>
                    <mat-icon class="icon-warn" svgIcon="close" *ngSwitchCase="false"></mat-icon>
                    Content
                </h3>
            </div>

            <div [ngSwitch]="checkAudienceForm.valid">
                <h3 style="display:flex; align-items: center">
                    <mat-icon class="icon-success" svgIcon="check" *ngSwitchCase="true"></mat-icon>
                    <mat-icon class="icon-warn" svgIcon="close" *ngSwitchCase="false"></mat-icon>
                    Audience
                </h3>
            </div>

            <div [ngSwitch]="checkSendForm.valid">
                <h3 style="display:flex; align-items: center">
                    <mat-icon class="icon-success" svgIcon="check" *ngSwitchCase="true"></mat-icon>
                    <mat-icon class="icon-warn" svgIcon="close" *ngSwitchCase="false"></mat-icon>
                    Send
                </h3>
            </div>

        </mat-expansion-panel> -->
        <img src="../../../assets/images/preview.jpg" alt="">
        <mat-card>Coming soon.</mat-card>
    </div>
    <div class="content">
        <mat-horizontal-stepper #stepper selectedIndex="0">
            <mat-step [stepControl]="contentForm">
                <ng-template matStepLabel>{{'app.wizard.panel.content.headline' | translate}}</ng-template>
                <mat-accordion class="step-content">
                    <mat-expansion-panel hideToggle [expanded]="contentPanel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon svgIcon="pencil-outline"></mat-icon>
                                {{'app.wizard.panel.content.expansionPanel.headline1' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <form [formGroup]="contentForm">
                            <mat-form-field class="full-width">
                                <mat-label>{{'app.wizard.panel.content.titleLabel' | translate}}
                                </mat-label>
                                <input maxlength="50" matInput formControlName="title" required>
                                <span matSuffix>{{contentForm.value.title.length}}/50</span>
                            </mat-form-field>
                                    <mat-form-field class="full-width message">
                                        <mat-label>{{'app.wizard.panel.content.messageLabel' | translate}}
                                        </mat-label>
                                        <textarea [maxlength]="maxMessageLength" matInput formControlName="message"
                                            required *ngIf="!features.placeholder"></textarea>
                                            <div *ngIf="features.placeholder" >
                                                <textarea [maxlength]="maxMessageLength" matInput formControlName="message"
                                                required [matAutocomplete]="autoPlaceholder"></textarea>
                                                
                                                <mat-autocomplete #autoPlaceholder="matAutocomplete" (optionSelected)="addPlaceholder($event, contentForm.value)">
                                                    <mat-option *ngFor="let option of placeholders" [value]="contentForm.value.message + '[' + option.itemid + '|placeholder]'" >
                                                        [{{option.name}}|placeholder]
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                        
                                        <mat-hint *ngIf="contentForm.value.message.length > 420" align="start">
                                            {{'app.wizard.panel.content.warning' | translate}}
                                        </mat-hint>
                                        <mat-hint align="end">{{contentForm.value.message.length}}/{{maxMessageLengthVisable}}
                                        </mat-hint>
                                    </mat-form-field>

                                    <div class="tagging-info" *ngIf="features.placeholder" >
                                        <mat-hint>
                                            {{'app.wizard.panel.content.placeholderInfo.partOne' | translate}} <span [matTooltip]="'app.wizard.panel.content.placeholderInfo.tooltip' | translate" >{{'app.wizard.panel.content.placeholderInfo.tooltipText' | translate}}</span> {{'app.wizard.panel.content.placeholderInfo.partTwo' | translate}} 
                                            <br> 
                                            {{'app.wizard.panel.content.placeholderInfo.example' | translate}}
                                        </mat-hint>
                                    </div>
                            <div class="icon-image-option" *ngIf="features.images" >
                                <div formGroupName="imageUrl">
                                    <mat-checkbox formControlName="isSet" (change)="checkImageIcon()" color="primary">
                                        {{'app.wizard.panel.content.attachImageLabel' | translate}}
                                    </mat-checkbox>
                                    <mat-form-field class="full-width" appearance="fill"
                                    *ngIf="contentForm.value.imageUrl.isSet">
                                    <span matPrefix>https://</span>
                                    <input placeholder="www.example.com" formControlName="url" matInput type="text">
                                </mat-form-field>
                            </div>
                            <div formGroupName="iconUrl">
                                <mat-checkbox formControlName="isSet" (change)="checkImageIcon()" color="primary">
                                    {{'app.wizard.panel.content.attachIconLabel' | translate}}
                                </mat-checkbox>
                                    <mat-form-field class="full-width" appearance="fill"
                                        *ngIf="contentForm.value.iconUrl.isSet">
                                        <span matPrefix>https://</span>
                                        <input placeholder="www.example.com" formControlName="url" matInput type="text">
                                    </mat-form-field>
                                </div>
                            </div>

                            <p>{{'app.wizard.panel.content.hint' | translate}}</p>
                            <div formArrayName="interaction">
                                <div *ngFor="let action of actionForms.controls; let i = index; let first = first; let last = last">
                                    <mat-hint class="action-hint" *ngIf="i === 1" >
                                        {{'app.wizard.panel.content.actionHint' | translate }}
                                    </mat-hint>
                                    <div class="action-wrapper full-width">
                                    <button class="remove-action" *ngIf="!first" (click)="deleteAction(i)"
                                        mat-flat-button mat-icon-button>
                                        <mat-icon svgIcon="trash-can-outline"></mat-icon>
                                    </button>
                                    <mat-form-field appearance="fill" [formGroupName]="i">
                                        <mat-label>{{'app.wizard.panel.content.actionLabel' | translate}}</mat-label>

                                        <mat-select (selectionChange)="changeAction(i)"
                                            formControlName="action">
                                            <mat-option *ngFor="let item of actions" [value]="item.name"
                                                [attr.field]="item.name">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div *ngIf="!first" >
                                        <mat-form-field appearance="fill" [formArrayName]="i">
                                            <mat-label>Name</mat-label>
                                            <input formControlName="name" matInput type="text">
                                        </mat-form-field>
                                    </div>

                                    <div [formArrayName]="i">
                                        <div *ngFor="let field of actions">
                                            <div
                                                *ngIf="field.name === actionForms.value[i].action &&
                                                (field.custom_fields | json) != '{}'">
                                                <mat-form-field formGroupName="custom" appearance="fill">
                                                    <mat-label class="action-label">{{field.key}}</mat-label>
                                                    <input formControlName="id" matInput
                                                        type="text">
                                                </mat-form-field>
                                            </div>
                                            <!-- <div
                                                *ngIf="field.name === actionForms.value[i].action && field.custom_fields.hasOwnProperty('link')">
                                                <mat-form-field class="link" formGroupName="custom" appearance="fill">
                                                    <mat-label>Link</mat-label>
                                                    <input formControlName="id" matInput
                                                        type="text">
                                                </mat-form-field>
                                            </div> -->
                                        </div>
                                        </div>
                                    </div>

                                    <!-- <div class="link-input" *ngIf="action.value.action === 'openNews'; else notWeb">
                                        <div [formGroupName]="i">

                                            <mat-form-field formGroupName="custom" appearance="fill">

                                                <span matPrefix>https://</span>
                                                <input placeholder="www.example.com" type="text" matInput
                                                    formControlName="actioncustom1">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <ng-template #notWeb>
                                        <div class="link-input">
                                            <mat-form-field [formGroupName]="i" appearance="fill">
                                                <mat-label>{{'APP__type' | translate}}</mat-label>
                                                <input type="text" matInput formControlName="type">
                                            </mat-form-field>
                                        </div>
                                        <div class="link-input">
                                            <mat-form-field [formGroupName]="i" appearance="fill">
                                                <mat-label>{{'APP__itemId' | translate}}</mat-label>
                                                <input type="text" matInput formControlName="itemid">
                                            </mat-form-field>
                                        </div>
                                    </ng-template> -->
                                </div>

                                <mat-divider></mat-divider>
                                <br>

                                <button (click)="addActions()" [disabled]="actionForms.length === 3" mat-flat-button
                                    color="primary" class="add-button"
                                    [matTooltip]="'app.wizard.panel.content.actionHint' | translate"
                                    [matTooltipPosition]="'right'"
                                    >
                                    <mat-icon svgIcon="plus-circle-outline"></mat-icon>
                                    {{'app.wizard.panel.content.addAction' | translate}}
                                </button>
                                <mat-hint>{{actionForms.length}} {{'app.wizard.panel.content.numberOfActions' | translate}}</mat-hint>
                            </div>

                        </form>

                    </mat-expansion-panel>
                   <mat-expansion-panel *ngIf="features.template && (contentTemplates | async)?.length !== 0" hideToggle
                       (click)="setPanel(false)">
                       <mat-expansion-panel-header>
                           <mat-panel-title>
                               <mat-icon svgIcon="format-list-bulleted-square"></mat-icon>
                               {{'app.wizard.panel.content.expansionPanel.headline2' | translate}}
                           </mat-panel-title>
                       </mat-expansion-panel-header>

                       <mat-table [dataSource]="contentTemplates">

                           <ng-container matColumnDef="title">
                               <mat-header-cell *matHeaderCellDef> {{'app.wizard.panel.content.table.title' | translate}}
                               </mat-header-cell>
                               <mat-cell *matCellDef="let element"> 
                                   <div class="cell-break">
                                       <h3>
                                           {{element.title}}
                                        </h3>
                                        <span>
                                            {{element.text}}
                                        </span>
                                    </div>
                                </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="action">
                               <mat-header-cell *matHeaderCellDef> {{'app.wizard.panel.content.table.actions' | translate}}
                               </mat-header-cell>
                               <mat-cell *matCellDef="let element">
                                   <span *ngFor="let action of element.interaction, let last = last">
                                       {{ action.action }}<span *ngIf="!last">,&nbsp;</span>
                                   </span>
                               </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="select">
                               <mat-header-cell *matHeaderCellDef></mat-header-cell>
                               <mat-cell *matCellDef="let element"><button mat-flat-button
                                       (click)="selectContent(element)">Select content</button></mat-cell>
                           </ng-container>

                           <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                           <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                       </mat-table>
                       <button class="prev" (click)="prevPage()" mat-flat-button [disabled]="searchParamContent.offset === 0">
                        <mat-icon svgIcon="chevron-left"></mat-icon>
                    </button>
                    <button class="next" (click)="nextPage()" mat-flat-button>
                        <mat-icon svgIcon="chevron-right"></mat-icon>
                    </button>
                    <span> {{currentPage}} / {{contentTemplateSize / searchParamContent.limit | number: '1.0-0'}}</span>
                   </mat-expansion-panel>


                </mat-accordion>
                <!-- <div class="button-group">
                    <button class="hint-button" (click)="helpDialog()" mat-flat-button>
                        <mat-icon svgIcon="help-circle-outline"></mat-icon>
                        {{'APP_userhelp' | translate}}
                    </button>

                    <button hidden #nextButton matStepperNext>{{'APP__next' | translate}}</button>
                    <button hidden #backButton matStepperPrevious>{{'APP__back' | translate}}</button>
                </div> -->
            </mat-step>
            <mat-step [stepControl]="audienceForm">
                <ng-template matStepLabel>{{'app.wizard.panel.audience.headline' | translate}}</ng-template>
                <mat-accordion class="step-content">
                    <mat-expansion-panel hideToggle expanded="true"
                        >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon svgIcon="account-group-outline"></mat-icon>
                                {{'app.wizard.panel.audience.expansionPanel.headline1' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <form [formGroup]="audienceForm">
                            <div *ngIf="!uploadingCSV; else spinner" class="audience-selector">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'app.wizard.panel.audience.audienceLabel' | translate}}</mat-label>
                                    <mat-select formControlName="audience" (selectionChange)="getBothAudienceCount()">
                                        <mat-option [value]="allAudience" >All</mat-option>
                                        <mat-option *ngFor="let audience of audienceList" [value]="audience"> 
                                            {{audience.name}}
                                            {{audience.createTime | localDate: 'short'}}
                                            (Last updated: {{convertDate(audience.create_time)}})
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button *ngIf="features.import"  mat-flat-button (click)="uploader.click()"
                                        [matTooltipClass]="'multiline-tooltip'"
                                        matTooltipPosition="left"
                                        [matTooltip]="'app.wizard.panel.audience.importInfo' | translate">
                                    <mat-icon svgIcon="file-import"></mat-icon>
                                    {{'app.wizard.panel.audience.importButton' | translate}}
                                </button>
                                <input hidden accept=".csv" #uploader (change)="uploadFile($event)" type="file" name="csv"
                                    id="csvImport">
                            </div>
                            <ng-template #spinner >
                                <mat-spinner></mat-spinner>
                            </ng-template>
                            <div *ngIf="audienceForm.value.audience.length !== 0" class="details">
                                <div class="potential-reach user-count" *ngIf="audienceForm.value.audience.length !== 0">
                                    <mat-icon svgIcon="subdirectory-arrow-right" ></mat-icon>
                                    <h3 [innerHTML]="'app.wizard.panel.audience.users' | translate :{output: overallReach | numberFormat}">
                                    </h3>
                                </div>

                                <!-- <div class="tags" *ngIf="audienceForm.value.audience.filter_keywords.length !== 0">
                                    <mat-chip-list>
                                        <mat-chip *ngFor="let tag of audienceForm.value.audience.filter_keywords">{{tag}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </div> -->

                            </div>
                                <mat-divider></mat-divider>
                        </form>

<!--                        <mat-expansion-panel-header>-->
<!--                            <mat-panel-title>-->
<!--                                <mat-icon svgIcon="filter-outline"></mat-icon>-->
<!--                                {{'app.wizard.panel.audience.expansionPanel.headline2' | translate}}-->
<!--                            </mat-panel-title>-->
<!--                        </mat-expansion-panel-header>-->
                        <div class="custom-audience">
                            <mat-form-field
                                [ngClass]="{'hasContent': appliedFilters.length >= 1, 'ng-invalid mat-form-field-invalid': checkCustomList.invalid && checkFilterCtrl.touched}">
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let filter of appliedFilters" [selectable]="selectable"
                                        [removable]="removable" (removed)="remove(filter, true)">
                                        {{filter}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input matInput [placeholder]="'app.wizard.panel.audience.audienceFilter' | translate" #filterInput
                                        [formControl]="filterCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, true)">
                                    <mat-option *ngFor="let filter of filterObservable | async" [value]="filter">
                                        {{filter}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-icon class="filter-help-icon" svgIcon="help-circle-outline" [matTooltip]="'app.wizard.panel.audience.tooltip' | translate" matTooltipPosition="left" ></mat-icon>
                            <!-- <mat-hint>Filters </mat-hint> -->
                            <button *ngIf="appliedFilters.length >= 1" mat-flat-button color="primary"
                                (click)="resetFilter()">
                                <mat-icon svgIcon="autorenew"></mat-icon>
                                {{'app.wizard.panel.audience.resetButton' | translate}}
                            </button>
                        </div>

                        <mat-error class="filter-error" *ngIf="filterCtrl.touched && filterCtrl.value !== null && !filterCtrl.value.split(':')[1]">
                            {{'app.wizard.panel.audience.filter.errors.notSet' | translate }} "{{filterCtrl.value}}"
                        </mat-error>

                        <mat-error class="filter-error" *ngIf="filterCtrl.touched && filterCtrl.value !== null && filterCtrl.value.split(':')[1]">
                            {{'app.wizard.panel.audience.filter.errors.notApplied' | translate: {output: filterCtrl.value} }}
                        </mat-error>


                        <div class="potential-reach user-count" *ngIf="appliedFilters.length !== 0">
                            <mat-icon svgIcon="subdirectory-arrow-right" ></mat-icon>
                            <h3 [innerHTML]="'app.wizard.panel.audience.usersOf' | translate :{output: filteredReach | numberFormat, reach: overallReach}">
                            </h3>
                        </div>

                    </mat-expansion-panel>
<!--                    <mat-expansion-panel hideToggle>-->
<!--                        <mat-expansion-panel-header>-->
<!--                            <mat-panel-title>-->
<!--                                <mat-icon svgIcon="file-compare"></mat-icon>-->
<!--                                {{'app.wizard.panel.audience.expansionPanel.headline3' | translate}}-->
<!--                            </mat-panel-title>-->
<!--                        </mat-expansion-panel-header>-->


<!--                        <form [formGroup]="audienceForm">-->
<!--                            <div>-->
<!--                                <mat-form-field appearance="fill">-->
<!--                                    <mat-label>{{'app.wizard.panel.audience.audienceLabel' | translate}}</mat-label>-->
<!--                                    <mat-select formControlName="audience" (selectionChange)="getAudienceCount()">-->
<!--                                        <mat-option [value]="allAudience" >All</mat-option>-->
<!--                                        <mat-option *ngFor="let audience of audienceList" [value]="audience">-->
<!--                                            {{audience.name}}-->
<!--                                            (Last updated: {{convertDate(audience.create_time)}})-->
<!--                                        </mat-option>-->
<!--                                    </mat-select>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->

<!--                            <div class="custom-audience">-->
<!--                                <mat-form-field-->
<!--                                        [ngClass]="{'hasContent': appliedFilters.length >= 1, 'ng-invalid mat-form-field-invalid': checkCustomList.invalid && checkFilterCtrl.touched}">-->
<!--                                    <mat-chip-list #chipList>-->
<!--                                        <mat-chip *ngFor="let filter of appliedFilters" [selectable]="selectable"-->
<!--                                                  [removable]="removable" (removed)="remove(filter)">-->
<!--                                            {{filter}}-->
<!--                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
<!--                                        </mat-chip>-->
<!--                                        <input matInput [placeholder]="'app.wizard.panel.audience.audienceFilter' | translate" #filterInput-->
<!--                                               [formControl]="filterCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"-->
<!--                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--                                               (matChipInputTokenEnd)="add($event)">-->
<!--                                    </mat-chip-list>-->
<!--                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
<!--                                        <mat-option *ngFor="let filter of filteredFilters | async" [value]="filter">-->
<!--                                            {{filter}}-->
<!--                                        </mat-option>-->
<!--                                    </mat-autocomplete>-->
<!--                                </mat-form-field>-->
<!--                                <button *ngIf="appliedFilters.length >= 1" mat-flat-button color="primary"-->
<!--                                        (click)="resetFilter()">-->
<!--                                    <mat-icon svgIcon="autorenew"></mat-icon>-->
<!--                                    {{'app.wizard.panel.audience.resetButton' | translate}}-->
<!--                                </button>-->
<!--                            </div>-->



<!--                            <div *ngIf="audienceForm.value.audience.length !== 0" class="details">-->
<!--                                <mat-divider></mat-divider>-->
<!--                                <div class="potential-reach" *ngIf="audienceForm.value.audience.length !== 0">-->
<!--                                    <h3-->
<!--                                            [innerHTML]="'app.wizard.panel.audience.users' | translate :{output: overallReach |-->
<!--                                        numberFormat}">-->
<!--                                    </h3>-->
<!--                                </div>-->

<!--                                &lt;!&ndash; <div class="tags" *ngIf="audienceForm.value.audience.filter_keywords.length !== 0">-->
<!--                                    <mat-chip-list>-->
<!--                                        <mat-chip *ngFor="let tag of audienceForm.value.audience.filter_keywords">{{tag}}-->
<!--                                        </mat-chip>-->
<!--                                    </mat-chip-list>-->
<!--                                </div> &ndash;&gt;-->

<!--                            </div>-->
<!--                        </form>-->


<!--                    </mat-expansion-panel>-->
                </mat-accordion>
                <!-- <div class="button-group">
                    <button class="hint-button" (click)="helpDialog()" mat-flat-button>
                        <mat-icon svgIcon="help-circle-outline"></mat-icon>
                        {{'APP_userhelp' | translate}}
                    </button>

                    <button mat-flat-button class="hint-button" matStepperPrevious>{{'APP__back' | translate}}</button>
                    <button mat-flat-button color="primary" matStepperNext>{{'APP__next' | translate}}</button>
                </div> -->
            </mat-step>
            <mat-step [stepControl]="sendForm">
                <ng-template matStepLabel>{{'app.wizard.panel.send.headline' | translate}}</ng-template>
                <form [formGroup]="sendForm">
                    <mat-accordion>
                        <mat-expansion-panel hideToggle (opened)="checkPushType(0)" [expanded]="steps.third === 0" (click)="canSend = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon svgIcon="email-send-outline"></mat-icon>
                                    {{'app.wizard.panel.send.expansionPanel.headline1' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                <mat-checkbox formControlName="sendNow" color="primary">
                                    {{'app.wizard.panel.send.confirmLabel' | translate}}
                                </mat-checkbox>
                            </p>
                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle (opened)="checkPushType(1)" [expanded]="steps.third === 1"
                            (click)="canSend = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon svgIcon="clock-outline"></mat-icon>
                                    {{'app.wizard.panel.send.expansionPanel.headline2' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="schedule">
                                <div formGroupName="schedule">
                                    <mat-form-field color="primary" appearance="fill">
                                        <mat-label>{{'app.wizard.panel.send.dateLabel' | translate}}</mat-label>
                                        <input readonly formControlName="date" [min]="today" matInput [matDatepicker]="picker1">
                                        <mat-datepicker-toggle matSuffix [for]="picker1">
                                            <mat-icon matDatepickerToggleIcon svgIcon="calendar-month-outline">
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker1 touchUi color="primary" [startAt]="today">
                                        </mat-datepicker>
                                    </mat-form-field>


                                    <mat-form-field class="time" appearance="fill">
                                        <mat-label>{{'app.wizard.panel.send.timeLabel' | translate}}</mat-label>
                                        <input formControlName="time" matInput type="time">
                                        <mat-icon matSuffix svgIcon="clock-outline"></mat-icon>
                                    </mat-form-field>
                                    <!-- <mat-form-field appearance="fill">
                                        <mat-label>{{'APP__labelRepeat' | translate}}</mat-label>
                                        <mat-select formControlName="repeat">
                                            <mat-option value="once">{{'APP__repeatOnce' | translate}}</mat-option>
                                            <mat-option value="daily">{{'APP__repeatDaily' | translate}}</mat-option>
                                            <mat-option value="weekly">{{'APP__repeatWeekly' | translate}}</mat-option>
                                            <mat-option value="monthly">{{'APP__repeatMonthly' | translate}}
                                            </mat-option>
                                            <mat-option value="yearly">{{'APP__repeatYearly' | translate}}
                                                {{selectedDate | localDate: 'dd.MM'}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                                </div>
                            </div>
                        </mat-expansion-panel>
                       <mat-expansion-panel *ngIf="features.automation" hideToggle (opened)="checkPushType(2)"
                           [expanded]="steps.third === 2"
                           (click)="canSend = false">
                           <mat-expansion-panel-header>
                               <mat-panel-title>
                                   <mat-icon svgIcon="cog-outline"></mat-icon>
                                   {{'app.wizard.panel.send.expansionPanel.headline3' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="automate-content" formGroupName="trigger">

                                <mat-form-field color="primary" appearance="fill">
                                    <mat-label>{{'app.wizard.panel.send.dateLabel' | translate}}</mat-label>
                                    <input readonly formControlName="send_time" [min]="today" matInput [matDatepicker]="picker2">
                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                        <mat-icon matDatepickerToggleIcon svgIcon="calendar-month-outline">
                                        </mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker2 touchUi color="primary" [startAt]="today">
                                    </mat-datepicker>
                                </mat-form-field>

                           <mat-form-field class="time" appearance="fill">
                            <mat-label>{{'app.wizard.panel.send.timeLabel' | translate}}</mat-label>
                            <input formControlName="interval_time" matInput type="time">
                            <mat-icon matSuffix svgIcon="clock-outline"></mat-icon>
                        </mat-form-field>
                           <mat-form-field class="full-width" appearance="fill">
                               <mat-label>{{'app.wizard.panel.send.triggerLabel' | translate}}</mat-label>
                               <mat-select class="uppercase" formControlName="interval_type" (selectionChange)="checkForm($event)">
                                   <mat-option *ngFor="let interval of intervalTypes" [value]="interval">{{interval}}</mat-option>
                               </mat-select>
                           </mat-form-field>
                           <mat-form-field *ngIf="triggers.value.interval_type === 'weekly'" class="full-width" appearance="fill">
                               <mat-label>{{'app.wizard.panel.send.triggerLabel2' | translate}}</mat-label>
                               <mat-select class="uppercase" formControlName="interval_weekday">
                                   <mat-option *ngFor="let days of intervalWeekday" [value]="days">{{days}}</mat-option>
                               </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </form>
            <!-- <div class="button-group">
                <button class="hint-button" (click)="helpDialog()" mat-flat-button>
                    <mat-icon svgIcon="help-circle-outline"></mat-icon>
                    {{'APP_userhelp' | translate}}
                </button>
                <button mat-flat-button class="hint-button" matStepperPrevious>{{'APP__back' | translate}}</button>
                <button [disabled]="checkContentForm.invalid || checkAudienceForm.invalid || checkSendForm.invalid"
                mat-flat-button (click)="finishWizard()" color="primary" [ngSwitch]="selectedPushType">
                <span *ngSwitchCase="0">{{'APP__buttonSend' | translate}}</span>
                <span *ngSwitchCase="1">{{'APP__buttonSchedule' | translate}}</span>
                <span *ngSwitchCase="2">{{'APP__buttonAutomate' | translate}}</span>
            </button>
        </div> -->
    </mat-step> 
</mat-horizontal-stepper>

    </div>

    <div class="loading-overlay" *ngIf="sendingCampaign">
        <!-- <mat-spinner></mat-spinner> -->
        <div class="loading-background">
            <img class="paperplanel-loading" src="../../../assets/images/paperplane.svg" alt="">
        </div>
        <h2>{{'app.wizard.overlay.headline' | translate}}</h2>
    </div>
</div>
<mat-dialog-actions>
    <div class="button-group">
        <button class="hint-button" (click)="helpDialog()" mat-flat-button>
            <mat-icon svgIcon="help-circle-outline"></mat-icon>
            {{'app.button.help' | translate}}
        </button>
        <button *ngIf="stepper.selectedIndex >= 1" mat-flat-button (click)="goBack()"
                class="hint-button">{{'app.button.back' | translate}}</button>
        <button *ngIf="stepper.selectedIndex <= 1" mat-flat-button (click)="goForward()" color="primary">
            {{'app.button.next' | translate}}
        </button>
        <button *ngIf="stepper.selectedIndex >= 2"
                [disabled]="checkContentForm.invalid || checkAudienceForm.invalid || checkSendForm.invalid"
                mat-flat-button (click)="finishWizard()" color="primary" [ngSwitch]="selectedPushType">
            <span *ngSwitchCase="0">{{'app.button.send' | translate}}</span>
            <span *ngSwitchCase="1">{{'app.button.schedule' | translate}}</span>
            <span *ngSwitchCase="2">{{'app.button.automate' | translate}}</span>
        </button>
    </div>
</mat-dialog-actions>
