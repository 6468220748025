import { LicenseService } from './../../services/license/license.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-setup",
  templateUrl: "./setup.component.html",
  styleUrls: ["./setup.component.scss"],
})
export class SetupComponent implements OnInit {
  environment = environment;
  constructor(
  ) {}

  ngOnInit(): void {
  }
}
