import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';


@Injectable({ providedIn: 'root' })
export class SessionService {
  private _locale: string;

  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || 'en';
  }

  registerCulture(culture: string) {
    if (!culture) {
      return;
    }
    if (culture !== 'de') {
      this.locale = 'en';
      return;
    }
    this.locale = culture;

    // Register locale data since only the en-US locale data comes with Angular
    switch (culture) {
      case 'de': {
        registerLocaleData(localeDe);
        break;
      }
      default: {
        registerLocaleData(localeEn);
        break;
      }
    }
  }
}
