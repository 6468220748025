<header>
    <img class="cloud" src="../../../assets/images/setup_image_cloud_left.svg" alt="">
    <img class="paperplane" src="../../../assets/images/paperplane.svg" alt="">
    <div class="container">
        <h2>Settings <br><small>(coming soon)</small></h2>
    </div>
</header>
<main>
    <div class="container settings-panel">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Account</strong>
                    </mat-panel-title>
                    <mat-panel-description>Manage your account</mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field class="wide" appearance="fill">
                    <mat-label>Customer</mat-label>
                    <input matInput disabled [value]="config.customer.name" type="text">
                </mat-form-field>
                <form class="account-form" [formGroup]="userForm">
                    <mat-form-field appearance="fill">
                        <mat-label>Your name</mat-label>
                        <input formControlName="name" type="text" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Your e-mail address</mat-label>
                        <input formControlName="email" type="email" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Your old password</mat-label>
                        <input type="password" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Your new password</mat-label>
                        <input type="password" matInput>
                    </mat-form-field>
                    <button mat-icon-button>
                        <mat-icon svgIcon="check" ></mat-icon>
                    </button>
                </form>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>User management</strong>
                    </mat-panel-title>
                    <mat-panel-description>Add or remove user and manage their permissions.</mat-panel-description>
                </mat-expansion-panel-header>
                <p>I'm visible because I am open</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>User list</strong>
                    </mat-panel-title>
                    <mat-panel-description>
                        See all your connected users.
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>I'm visible because I am open</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Update license</strong>
                    </mat-panel-title>
                    <mat-panel-description>
                        Renew or update your license.
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>I'm visible because I am open</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Sync</strong>
                    </mat-panel-title>
                    <mat-panel-description>
                        Sync all your settings
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>I'm visible because I am open</p>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
</main>

<app-footer></app-footer>