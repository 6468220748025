<h2 mat-dialog-title>{{data?.title}}</h2>

<mat-dialog-content>
    <p>{{data?.description}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog(false)">
        No
    </button>
    <button color="warn" mat-flat-button cdkFocusInitial (click)="closeDialog(true)">
        Yes
    </button>
</mat-dialog-actions>
