import * as ConfigActions from './config.actions';

let initialState = null;

export function configStore(state = initialState, action: ConfigActions.ConfigActions) {
  switch (action.type) {
    case ConfigActions.UPDATE_CONFIG:
      return { ...action.payload };

    case ConfigActions.DELETE_CONFIG:
      return initialState;

    default:
      return state;
  }
}
