import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Audience} from '../../../interfaces/audience';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CdkDetailRowDirective} from '../../../directives/cdk-detail-row.directive';

@Component({
  selector: 'app-audience-table-card',
  templateUrl: './audience-table-card.component.html',
  styleUrls: ['./audience-table-card.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'void',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AudienceTableCardComponent implements OnInit {
  @Input() currentPage = 0;
  @Input() overallSize = 0;
  @Input() hasPrevPage = false;
  @Input() hasNextPage = false;
  @Input() loadingFailed = false;
  @Input() tableIsLoading = false;
  @Input() displayedColumns: string[] = [];
  @Input() dataSource: MatTableDataSource<Audience>;

  @Output() prevPage: EventEmitter<void> = new EventEmitter();
  @Output() nextPage: EventEmitter<void> = new EventEmitter();
  @Output() reload: EventEmitter<boolean> = new EventEmitter();
  @Output() editAudience: EventEmitter<Audience> = new EventEmitter();
  @Output() deleteAudience: EventEmitter<Audience> = new EventEmitter();

  private openedRow: CdkDetailRowDirective;

  constructor() {}

  ngOnInit(): void {}

  onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
    if (this.openedRow && this.openedRow.expended) {
      this.openedRow.toggle();
    }
    this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
  }
}
