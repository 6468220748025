import { VersionService } from './../../services/version/version.service';
import { Observable } from 'rxjs';
import { Config, NavigationMenu } from './../../interfaces/config';
import { DetailResponse } from './../../interfaces/base/response';
import { ConfigService } from './../../services/config/config.service';
import { ApiService } from './../../services/api.service';
import { UserService } from './../../services/user.service';
import { Router } from '@angular/router';
import { LogoutComponent } from './../dialogs/logout/logout.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import * as fromApp from 'src/app/store/app.reducer';
import * as configActions from 'src/app/store/config/config.actions';
import * as versionActions from 'src/app/store/version/version.actions';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  username = '...';
  showProfile = false;
  navigationMenu: Array<NavigationMenu> = [];
  config: Observable<Config>;

  enableAudience = false;
  enableContent = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private configService: ConfigService,
    private versionService: VersionService,
    private store: Store<fromApp.AppState>,
  ) {
    this.config = this.store.select('config');
  }

  ngOnInit(): void {
    this.apiService.getToken();
    this.username = this.userService.getUserInformation().name;

    this.configService.findOne('').subscribe(
      (response: DetailResponse<Config>) => {
        // this.navigationMenu = response.detail.navigation_menu;
        if (response.detail.features.find((feature) => feature.itemid === 'audience_groups')) {
          this.enableAudience = true;
        }
        if (response.detail.features.find((feature) => feature.itemid === 'content_template')) {
          this.enableContent = true;
        }
        this.store.dispatch(new configActions.UpdateConfig(response.detail));
      },
      (err) => {
        switch (err.error.status) {
          case 401:
            this.store.dispatch(new configActions.DeleteConfig());
            this.router.navigate(['/login']);
            break;
          case 404:
            this.router.navigate(['/setup']);
            break;

          default:
            break;
        }
      },
    );
    this.versionService.getVersion().subscribe((res) => {
      this.store.dispatch(new versionActions.UpdateVersion(res.detail));
    });
  }

  toggleProfile() {
    this.showProfile = !this.showProfile;
  }
  logout() {
    const logOutDialog = this.dialog.open(LogoutComponent);
    logOutDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/login']);
        window.localStorage.clear();
      }
    });
  }
}
