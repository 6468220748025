<h1 mat-dialog-title>{{'app.dialog.help.headline' | translate}}</h1>
<mat-dialog-content>
    <h2>Create content</h2>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga ullam explicabo, cumque dolore vitae repellendus delectus sequi nemo! Facere recusandae labore doloribus porro aspernatur quam odit dignissimos quidem earum nobis impedit quaerat eaque non ipsam aperiam nemo numquam temporibus culpa, enim eius tempora fugit! Ullam laboriosam quae temporibus, quisquam minima voluptatum aliquam sit nulla impedit neque ea incidunt aspernatur ex deserunt. Quas nisi exercitationem quae, dolore ipsum doloribus quo expedita. Nesciunt quos quo laboriosam error quisquam. Quam ullam velit amet.</p>
    <mat-divider></mat-divider>
    <br>
    <h2>Select an audience</h2>
    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure quisquam aperiam tempore explicabo facere omnis ut quae? Facere quae quo harum mollitia nulla optio eveniet tempora a rerum ipsum ad quis eaque nobis vero sapiente doloribus aut, minus aliquid quidem. Impedit inventore non, quidem nisi quasi, quos nemo quia distinctio temporibus maiores, doloremque incidunt corporis esse quaerat autem rem iste voluptatibus fugiat labore. Reprehenderit autem delectus iste non esse! Quibusdam, enim veniam quaerat illo nobis voluptas omnis id nulla eaque cumque excepturi labore mollitia.</p>
<mat-divider></mat-divider>
    <br>
    <h2>Send notifcations</h2>
    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum a vero dolores cupiditate, porro esse rem magni suscipit vitae asperiores. Maiores sequi fugit natus velit saepe provident voluptatum ipsa suscipit?</p>
<mat-divider></mat-divider>
    <br>
    <h2>Automate and schedule</h2>
    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate, exercitationem deleniti debitis sequi ipsam facere obcaecati rem consequatur atque? Animi nihil commodi sint. In ipsa nam expedita soluta quo? Quisquam deserunt eligendi nesciunt, rerum quaerat doloribus sit molestiae, qui neque doloremque accusamus. Obcaecati, alias ipsum culpa amet nesciunt unde saepe nam voluptatem iure, cumque voluptas esse explicabo incidunt quidem. Aperiam, hic eaque? Non tempore veritatis natus accusamus similique eaque reprehenderit incidunt ad sapiente, illo voluptatem sed facere consectetur adipisci expedita dicta fuga. Reiciendis tempora doloribus repellendus aliquid.</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'app.button.close' | translate}}</button>
</mat-dialog-actions>
