import { Directive, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[appMatInputAutofocus]'
})
export class MatInputAutofocusDirective implements OnInit {

  constructor(private matInput: MatInput) { }

  ngOnInit(){
      this.matInput.focus();
  }

}
