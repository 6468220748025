import { ListResponse } from './../../../interfaces/base/response';
import { Audience } from './../../../interfaces/audience';
import { AudienceService } from './../../../services/audience/audience.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';


@Component({
  selector: 'app-show-recipients',
  templateUrl: './show-recipients.component.html',
  styleUrls: ['./show-recipients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowRecipientsComponent implements OnInit {
  audience: any = {name: ''};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private audienceService: AudienceService) { }

  ngOnInit(): void {
    this.audienceService.findAll().subscribe((res: ListResponse<Audience>) => {
      this.audience = res.list.find(x => x.itemid === this.data.audience_group_id);
    });

  }

}
