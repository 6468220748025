<footer>
    <div class="backgound">
        <img class="cloud-1" src="../../../assets/images/setup_image_cloud_left.svg" alt="cloud">
        <img class="cloud-2" src="../../../assets/images/setup_image_cloud_right.svg" alt="cloud">
    </div>
    <div class="container">
        <ul class="mg-list-horizontal">
            <li>
                <a href="https://www.mogree.com/datenschutz/" target="_blank">{{'app.footer.dataPolicy' | translate}}</a>
            </li>
            <li>
                <a href="https://www.mogree.com/impressum/" target="_blank">{{'app.footer.imprint' | translate}}</a>
            </li>
        </ul>
        <h1>Customer Portal {{ environment.appVersion }} <span *ngIf="(backendInfo | async)" >• API {{(backendInfo | async)?.version}} ({{(backendInfo | async)?.build_time | localDate: 'short'}})</span>
            <br> {{'app.copyright' | translate}} <a href="https://mogree.com" target="_blank">mogree</a></h1>
    </div>
</footer>
